import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import LOGO from '../../assets/LOGO-CHAPITRE-SIX.png';
import SUBLOGO from '../../assets/Deshistoires.svg';
import FLECHE from '../../assets/FLECHE.svg';
import './navbar.css';
import content from '../../content.json';
import { useFilter } from '../../FilterContext';
import BookingModule from '../ModuleResa/ModuleResa'; // Importation du module de réservation

const Navbar = ({ changePage, isTransparent, langue }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const [filtersVisible, setFiltersVisible] = useState(true);
  const [isSublogoVisible, setIsSublogoVisible] = useState(true);
  const [isBookingOpen, setIsBookingOpen] = useState(false); // État pour ouvrir/fermer le module de réservation

  const location = useLocation();
  const { selectedFilter, handleFilterChange } = useFilter();

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    setSubmenuOpen(false);
  };

  const handlePageChange = (path) => {
    window.location.href = path; // Force le rechargement de la page
  };

  const isFilterPage = location.pathname.includes('nos-actualites') || location.pathname.includes('les-hotels');
  const showSubLogo = !isFilterPage;

  useEffect(() => {
    const footer = document.querySelector('footer');

    if (!footer) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setFiltersVisible(false);
            setIsSublogoVisible(false);
          } else {
            setFiltersVisible(true);
            setIsSublogoVisible(true);
          }
        });
      },
      {
        root: null,
        threshold: 0.1,
      }
    );

    observer.observe(footer);

    return () => {
      observer.disconnect();
    };
  }, []);

  const toggleBookingModule = () => {
    setIsBookingOpen(!isBookingOpen); // **Modifié pour ouvrir/fermer le module de réservation**
  };

  return (
    <nav>
      <div className={`navContainer ${isTransparent ? 'transparent' : ''}`}>
        <div className="smallNavContainer">
          <div className="navRight">
            <div onClick={toggleMenu} className={`burger-icon ${isLoaded ? 'fade-in' : 'hidden'}`}>
              <svg width="38" height="20" viewBox="0 0 38 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                {!menuOpen && <rect x="38" y="20" width="38" height="0.999997" transform="rotate(-180 38 20)" fill="black" />}
                <rect x="38" y="10" width="38" height="0.999997" transform="rotate(-180 38 10)" fill="black" />
                {!menuOpen && <rect x="38" y="1" width="38" height="0.999997" transform="rotate(-180 38 1)" fill="black" />}
              </svg>
            </div>

            <Link className='containerLogo' to="/" onClick={() => handlePageChange('/')}>
              <img src={LOGO} alt="Logo" className="Logo" />
            </Link>

            {showSubLogo && isSublogoVisible && (
              <Link to="/" onClick={() => handlePageChange('/')}>
                <img src={SUBLOGO} alt="Sublogo" className={`Sublogo ${isLoaded ? 'fade-in' : 'hidden'}`} />
              </Link>
            )}

            {isFilterPage && filtersVisible && (
              <div className='filter'>
                <p onClick={() => handleFilterChange('Paris')} className={selectedFilter === 'Paris' ? 'active' : ''}>
                  Paris
                </p>
                <p onClick={() => handleFilterChange('Ailleurs')} className={selectedFilter === 'Ailleurs' ? 'active' : ''}>
                  Ailleurs
                </p>
                <p onClick={() => handleFilterChange('Tous')} className={selectedFilter === 'Tous' ? 'active' : ''}>
                  Tous
                </p>
              </div>
            )}
          </div>

          {/* Bouton de réservation qui ouvre le module */}
          <button
            className={`booking ${isLoaded ? 'fade-in' : 'hidden'}`}
            onClick={toggleBookingModule} 
          >
            {langue === 'fr' ? 'réserver' : 'book'}
          </button>
        </div>
      </div>

      {/* Si le module de réservation est ouvert, afficher le module */}
      {isBookingOpen && <BookingModule onClose={() => setIsBookingOpen(false)} />}

      <div className={`menu ${menuOpen ? 'open' : ''}`}>
        <div className="subMenu">
          <button className='lesHotelsMenu' onClick={() => setSubmenuOpen(!submenuOpen)}>{langue === 'fr' ? 'les hotels' : 'HOTELS'}</button>
          {submenuOpen && (
            <div className='containerSubMenuLink'>
              <div className="submenuLinks">
                <Link className='lesHotelsRedirec' to="/les-hotels" onClick={() => handlePageChange('/les-hotels')}>{langue === 'fr' ? 'TOUS LES HÔTELS' : 'ALL HOTELS'}</Link>
                <div className='ligneSubHotels'>
                  <a href="https://hotelhana-paris.com/" target="_blank" rel="noopener noreferrer">Hôtel Hana, Paris 2</a>
                  <img src={FLECHE} alt="Logo" className="ArrowHotels" />
                </div>
                <div className='ligneSubHotels'>
                  <a href="https://maisonsaintonge.com/" target="_blank" rel="noopener noreferrer">Maison Saintonge, Paris 3</a>
                  <img src={FLECHE} alt="Logo" className="ArrowHotels" />
                </div>
                <div className='ligneSubHotels'>
                  <a href="https://hoteldesacademies.fr/" target="_blank" rel="noopener noreferrer">Hôtel des Académies et des Arts, Paris 6</a>
                  <img src={FLECHE} alt="Logo" className="ArrowHotels" />
                </div>
                <div className='ligneSubHotels'>
                  <a href="https://monsieurgeorge.com/" target="_blank" rel="noopener noreferrer">Monsieur George, Paris 8</a>
                  <img src={FLECHE} alt="Logo" className="ArrowHotels" />
                </div>
                <div className='ligneSubHotels'>
                  <a href="https://www.buspalladium.com/" target="_blank" rel="noopener noreferrer">Le Bus Palladium, Paris 9</a>
                  <p>Prochainement</p>
                </div>
                <div className='ligneSubHotels'>
                  <a href="https://www.monsieurcadet.com/" target="_blank" rel="noopener noreferrer">Monsieur Cadet, Paris 9</a>
                  <img src={FLECHE} alt="Logo" className="ArrowHotels" />
                </div>
                <div className='ligneSubHotels'>
                  <a href="https://www.monsieuraristide.com/" target="_blank" rel="noopener noreferrer">Monsieur Aristide, Paris 18</a>
                  <img src={FLECHE} alt="Logo" className="ArrowHotels" />
                </div>
                <div className='ligneSubHotels'>
                  <a href="https://laponche.com/" target="_blank" rel="noopener noreferrer">Hôtel La Ponche, Saint-Tropez</a>
                  <img src={FLECHE} alt="Logo" className="ArrowHotels" />
                </div>
                <div className='ligneSubHotels'>
                  <a href="https://capdantibes-beachhotel.com/" target="_blank" rel="noopener noreferrer">Cap d’Antibes Beach Hotel, Cap d’Antibes</a>
                  <img src={FLECHE} alt="Logo" className="ArrowHotels" />
                </div>
                <div className='ligneSubHotels'>
                  <a href="https://www.lafoliebarbizon.com/" target="_blank" rel="noopener noreferrer">La Folie Barbizon, Barbizon</a>
                  <p>Prochainement</p>
                </div>
                <div className='ligneSubHotels'>
                  <a href="https://chaletsaintgeorges.com/fr" target="_blank" rel="noopener noreferrer">Chalet Saint-Georges, Megève</a>
                  <p>Prochainement</p>
                </div>
              </div>
            </div>
          )}
          <Link className='hotelMobileMenu' to="/les-hotels" onClick={() => handlePageChange('/les-hotels')}>{content.home.fr.landing.submenu1}</Link>
          <Link to="/histoire" onClick={() => handlePageChange('/histoire')}>{langue === 'fr' ? "l'histoire" : 'history'}</Link>
          <Link to="/nos-actualites" onClick={() => handlePageChange('/nos-actualites')}>{langue === 'fr' ? 'les actualités' : 'news'}</Link>
          <Link to="/nos-actualites" onClick={() => handlePageChange('/la-musique')}>{langue === 'fr' ? 'la musique' : 'music'}</Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
